<script setup lang="ts">
import { storeToRefs } from 'pinia';
import InsightsChart from '~/components/chart/InsightsChart.vue';
import HistoryTable from '~/components/history/HistoryTable.vue';
import SpellHeader from '~/components/spell/SpellHeader.vue';
import { useSpellsStore } from '~/stores/spells';

const spellsStore = useSpellsStore();
const { trigger } = storeToRefs(spellsStore);
</script>

<template>
  <NuxtLayout name="spell-tab" :wide="true">
    <SpellHeader v-if="trigger" />

    <div class="flex flex-col gap-4">
      <section class="flex flex-col w-full flex-1 items-start gap-2">
        <h2 class="title">Insights</h2>
        <div class="flex flex-col w-full gap-4 h-[340px]">
          <InsightsChart size="lg" class="my-m h-full" />
        </div>
      </section>

      <section class="flex flex-col w-full flex-1 items-start gap-2">
        <h2 class="title">History</h2>
        <div
          class="border-container p-m bg-white w-full flex flex-col rounded-2xl items-start"
        >
          <HistoryTable
            :fields="['status', 'startedAt', 'runtime', 'cost', 'result']"
            :page-size="5"
          />
        </div>
      </section>
    </div>
  </NuxtLayout>
</template>
